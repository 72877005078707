<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Ongoing Products</span>
    </el-card>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Product">
          <template slot-scope="scope">{{ scope.row.product.name }}</template>
        </el-table-column>
        <el-table-column label="Merchant">
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <el-table-column label="Groups count">
          <template slot-scope="scope">{{ scope.row.captainCount }}</template>
        </el-table-column>
        <el-table-column label="Orders count">
          <template slot-scope="scope">{{ scope.row.orderCount }}</template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <el-button size="mini" @click="showDetail(scope.row)"
              >Details</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { fetchOngoing } from '@/api/bulk';

export default {
  name: 'ongoingProduct',
  created() {
    this.getList();
  },
  data() {
    return {
      list: null,
      listLoading: true,
    };
  },
  methods: {
    getList() {
      fetchOngoing().then((response) => {
        this.list = response.data.data;
        this.listLoading = false;
      });
    },
    showDetail(row) {
      this.$router.push({
        path: '/bulkService/OngoingBulk',
        query: { id: row.product.id },
      });
    },
  },
};
</script>
